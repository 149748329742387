/**
 * Feature flags convention: go/frontend-feature-flags
 */
import { CONTROL, NOT_ENROLLED, VARIATION } from '@atlassian/jira-common-util-get-experiment-group';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import {
	ff,
	getFeatureFlagValue,
	getMultivariateFeatureFlag,
} from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	VARIANT_CONTEXTUAL,
	VARIANT_CONTEXTUAL_NOT_ENROLLED,
} from '@atlassian/jira-issue-view-common-constants/src/experiment-constants';

// mmondal start
export const isChildIssuePanelConfigurationEnabled = () =>
	ff('configurable-child-issues-panel_3ht06', false);
// mmondal end

// <ssaraswat>
export const isQuickActionExperimentFF = () => ff('issue_view_quick_actions_revamp_l8mbx');
export const isPinFieldExperimentAggFFEnabled = () => ff('pin_field_exp_launch_darkly_flag_ef4mc');
// </ssaraswat>

// <ugouhar>
export const isDataClassificationEnabled = () =>
	ff('jira-issue-history-data-classification_mhyjw', false);
// </ugouhar>

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isModernGlobalIssueCreateTriggerLinkedIssueEnabled = () =>
	ff('create-linked-issues-trigger-point-issue-view', false);

// <ForgeSlot>

// <ACaySlot>
// </ACaySlot>

// <mklus>
// </mklus>

// <lli7>
export const isRelayCascadingSelectFieldIssueViewIntegrationEnabled = () =>
	ff('issue.details.relay-cascading-select-field-issue-view-layout-integration');
// </lli7>
// Rach slot
// start

// 1
// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isIssueViewMigratedStreamEnabled = () =>
	ff('issue.details.migration-migrated-stream', false);

// 2
// 3
export const isGiraAGGConsistencyCheckEnabled = () =>
	getMultivariateFeatureFlag('issue.details.gira-agg-consistency-check-master-flag', 'OLD', [
		'OLD',
		'NEW',
		'CHECK',
		'CHECK_RETURN_NEW',
	]) === 'CHECK';

// Rach slot

// @pierrelingat slot start
export const isJiraIntelligentIssueBreakdownEnabled = () =>
	ff('jira-intelligent-issue-breakdown_18syl', false);

export const isFeatureFlagsToSLOAnalyticsEnabled = () =>
	ff('issue.details.feature-flags-to-slo-analytics', false);
// @pierrelingat slot end

// <rjiang slot>
// </rjiang>

// @wongben slot start
export const isA11yForIssueKeyStrikethroughEnabled = () =>
	ff('issue-view-a11y-for-issue-key-strikethrough_3xavq', false);

export const isIssueViewRemoveConnectOperationsFromCriticalFetchEnabled = () =>
	ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w');

// @wongben slot end

// SLOT START - media-feature-flags.js

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also

export const isMediaInlineEnabled = () => ff('issue.details.media-inline', false);

// SLOT END

// <growth team experiment slot>
export const isInviteFromAssignEnabled = (): boolean =>
	getMultivariateFeatureFlag('invite.from.assign', NOT_ENROLLED, [
		CONTROL,
		NOT_ENROLLED,
		VARIATION,
	]) === VARIATION;

export const getInviteFromAssignModalCohort = () =>
	getMultivariateFeatureFlag('invite.from.assign.modal', 'not-enrolled', [
		'control',
		'not-enrolled',
		'variation',
	]);

export const isInviteFromAssignModalFeEnabled = () =>
	ff('invite.from.assign.modal.fe.event', false);
// </growth team experiment slot>

// <lchen2 slot>

// </lchen2>

// <ashukla2>
export const isIssueCommentsHardLimitsFlagEnabled = () =>
	ff('comment-limit-exceed-warning-flag_3rx53', false);

export const isIssueWorklogsHardLimitsFlagEnabled = () =>
	ff('worklog-limit-exceed-warning-flag_hh86h', false);

export const isIssueConfluenceLinksHardLimitsFlagEnabled = () =>
	ff('confluence-link-limit-exceed-warning-flag_1ycku', false);

// </ashukla2>

// <lcaseiro slot>
export const issueDetailsHardLimitsMilestone1Enabled = () =>
	ff('issue.details.hard-limits.milestone-1');

export const issueDetailsHardLimitsIssueRemoteLinkEnabled = () =>
	ff('issue.details.hard-limits.issue-remote-link');

export const issueDetailsHardLimitsIssueAttachmentsEnabled = () =>
	ff('issue.details.hard-limits.issue-attachments-limit');

export const issueDetailsHardLimitsIssueWeblinksEnabled = () =>
	ff('issue.details.hard-limits.issue-weblinks-limit');

// </lcaseiro>

// <ksayapina slot>
export const isChangeboardingForParentEnabled = () =>
	ff('parent-field-changeboarding_4ljdy', false);
// </ksayapina>

// <midrew slot>
// </midrew slot>

// <leoc>
// </leoc>

export const getPerformanceTrackingConfiguration = () =>
	getFeatureFlagValue('issue.details.editor.performance-tracking', {});

export const isEditorUFOEnabled = () => ff('issue.details.editor.ufo', false);

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isSpecBasedValidatorEnabled = () => ff('issue.details.renderer.spec-based-validator');

// <dharnett>
// </dharnett1>
// </dharnett2>
// </dharnett3>
// </dharnett4>

// </dharnett5>
// </dharnett7>
// </dharnett8>
// </dharnett9>
// </dharnett10>
// </dharnett11>
// </dharnett12>
// </dharnett13>
// </dharnett>

// <gryffindor>
export const isModernGlobalIssueCreateTriggerEnabled = () =>
	ff('project.config.pce.modern.global-issue-create.trigger', false);

export const isCreateLinkedIssueTriggerPointEnabled = () =>
	ff('create-linked-issues-trigger-point-issue-view', false);

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isModernGlobalSubtaskIssueCreateTriggerEnabled = () =>
	ff('project.config.pce.modern.global-subtask-issue-create-trigger', false);

export const isModernGICEnabledForSubtask = () =>
	isModernGlobalIssueCreateTriggerEnabled() && isModernGlobalSubtaskIssueCreateTriggerEnabled();
// </gryffindor>

// <proforma>
export const isProformaHarmonisationFFEnabled = () =>
	ff('proforma-harmonisation-main_ca6ff', false);
// </proforma>

// <mliang>
export const isHideEpicLinkFromIssueViewForEpicRenamingBetaEnabled = () =>
	ff('hide-epic-link-from-issue-view-for-epic-renaming-beta_r1g77', false);

export const isRequestIsDoneWhenRequestingParentCandidatesEnabled = () =>
	ff('request-isdone-when-requesting-parent-candidates-and-display-in-parent-field_3becy', false);
// </mliang>

// <ksenia>
export const isRemoveParentLinkFromIssueViewEnabled = () =>
	ff('remove-parent-link-from-issue-view_4t7dy', false);

export const isParentInIssueViewEnabled = () => ff('parent-field-in-issue-view_x4zn4', false);

export const isIncrementPlanningM1Enabled = () =>
	ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1', false);
// </ksenia>

// <crobertson>

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isSwitcherooRealtimeExposuresTestFlagEnabled = () =>
	ff('switcheroo-realtime-exposures-test-flag_648u4', false);

// </crobertson>

// <hgao slot>
export const isIssueViewEndUserRequestTrialEnabled = () =>
	ff('issue-view-placeholder-end-user-request-modal_myc7n', false);
// </hgao>

// <ggutierrez2>
export const isPlaceHolderTemplateCoUseEnabled = () =>
	ff('confluence-template-placeholder_2bhgf', false);
// </ggutierrez2>

// <tchen3>

export const isRelayMigrationIssueFieldsEventsTransformerLdEnabled = () =>
	ff('relay-migration-issue-fields-events-transformer-ld', false);

export const isRelayMigrationIssueFieldsSoftRefreshLdEnabled = () =>
	ff('relay-migration-issue-fields-soft-refresh-ld');

export const isRelayMigrationIssueFieldsPriorityEnabled = () =>
	ff('relay-migration-issue-fields-priority_ri9vi');

export const isRelayMigrationIssueFieldsReporterEnabled = () =>
	ff('relay-migration-issue-fields-reporter_jfzs2');

// </tchen3>

// <smathew>
export const isRelayMigrationIssueFieldsParentEnabled = () =>
	ff('relay-migration-issue-fields-parent_5p1ac');
// </smathew>

// <kye>
// </kye>

// <fnio>
export const isFixIssueStatusTransitionLoadingEnabled = () =>
	ff('issue.details.fix-issue-status-transition-loading_6s8n8', false);

export const isFixIncorrectStateClosedSprintFieldEnabled = () =>
	ff('issue.details.fix-incorrect-state-closed-sprint-field_mpzbh', false);
// </fnio>

// <yihao>

// </yihao>

// <iyeskov>
// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isAltTextOnImagesEnabled = () => ff('editor.renderer-alt-text-on-images', false);

// </iyeskov>

// <garuda> <psinghal>
// added an api call on page-load to fetch offeringCatalogueFeatures from monolith
export const isOfferingCatalogueFeaturesEnabled = () =>
	ff('offeringcataloguefeatures-resource-layer-in-frontend_o5pnu', false);
// </garuda> </psinghal>

// <rnguyen>
export const isPriorityFieldTooltipEnabled = (): boolean =>
	ff('sea-2830-improve-accessibility-of-priority-field-in-backlog-card_k53tq', false);
// </rnguyen>

// <jsmTimeline>
export const isJsmTimelineEnabled = (): boolean => ff('jsm-timelines-phase-2_70gja', false);

// <jsmTimeline>

// <jsm-nebula>
export const isCannedResponseFFEnabled = () => ff('jsm-canned-response', false);

// <asingh16>
export const isRespondersFieldAvailableInAllProjectsEnabled = () =>
	ff('responders-field-in-all-projects-issues-ui', false);
// </asingh16>

// <knaithani>
export const isEcClientEnabled = () => {
	const experiences: string = getFeatureFlagValue<string>(
		'odin.ec.client.integration.issueview',
		'',
	);

	return experiences.length > 0;
};

// </knaithani>

// <asharma9>

export const isHideEmptyFieldsEnabled = () =>
	ff('ken-545-display-hide-when-empty-button-to-user-_fjpw9');

// </asharma9>

// <qzeng>
// </qzeng>

// <gchan3>
export const isGiraAGGIsArchivedEnabled = () => {
	const featureFlagValue = getMultivariateFeatureFlag(
		'issue.details.gira-agg-migration-isarchived',
		'OLD',
		['OLD', 'NEW', 'CHECK', 'CHECK_RETURN_NEW'],
	);

	return (
		featureFlagValue === 'NEW' ||
		featureFlagValue === 'CHECK_RETURN_NEW' ||
		featureFlagValue === 'CHECK'
	);
};
// </gchan3>

// </ssharma9>
export const isArchivedIssuesPageLinkEnabled = () => ff('enable-archived-issues-page-link', false);
// </ssharma9>

// <mbiniek>
export const isBM3EmitOnRafTopExperiencesEnabled = () =>
	ff('bm3.emit-on-raf.top-experiences', false);
// </mbiniek>

// <ttkachuk>
export const isCustomMessageForLinkingWithoutPermissionsEnabled = () =>
	ff('issue.details.custom-message-for-linking-with-no-permissions_k7qsn', false);

export const isSearchByEmailInMultiselectUserPickerEnabled = () =>
	ff('issue-view.enable-search-by-email-in-multiselect-user-picker-field', false);

export const isRelayReleaseVersionFieldIssueViewIntegrationEnabled = () =>
	ff('issue.details.relay-release-version-field-issue-view-integration', false);

// </ttkachuk>

// <myadamreddy>
// </myadamreddy>

// <jcastillo>
export const PlaceHolderTemplateFlags = {
	CROSS_FLOW: 'jira.issue-view.placeholder-template-cross-flow',
} as const;

export type PlaceHolderTemplateFlags =
	(typeof PlaceHolderTemplateFlags)[keyof typeof PlaceHolderTemplateFlags];

export const getPlaceHolderTemplateVariant = (experiment: PlaceHolderTemplateFlags): string =>
	experiment
		? getMultivariateFeatureFlag(experiment, NOT_ENROLLED, [
				VARIANT_CONTEXTUAL,
				VARIANT_CONTEXTUAL_NOT_ENROLLED,
				CONTROL,
				NOT_ENROLLED,
			])
		: NOT_ENROLLED;

// Whether the user is in the experiment for the dummy link (control or variant-contextual)
export const isConfluencePageDummyLinkExperiment = () =>
	[CONTROL, VARIANT_CONTEXTUAL].includes(
		getPlaceHolderTemplateVariant(PlaceHolderTemplateFlags.CROSS_FLOW),
	);

// Whether the user is in the dummy link variant
export const isConfluencePageDummyLinkEnabled = (experiment: PlaceHolderTemplateFlags): boolean =>
	getPlaceHolderTemplateVariant(experiment) === VARIANT_CONTEXTUAL;
// </jcastillo>

// <rliang>
// </rliang>

// <cturner>
export const isStopLoadingFieldConfigTwiceEnabled = () =>
	ff('jracloud-81255-stop-loading-field-config-twice_jfdek', false);
// </cturner>

// <gmountakis>
export const isNonCriticalGiraQueryExperienceTrackingEnabled = () =>
	ff('issue.details.non-critical-gira-query-experience-tracking', false);
// </gmountakis>

// <mrogulska>
// </mrogulska>

// <pravliv>

// </pravliv>

// <pracki>
// </pracki>

// <vpetrychuk>
// </vpetrychuk>

// <mhanna>
export const isCompassComponentsEnabled = () =>
	ff('compass-components-in-jira-components_xw42u', false);
// </mhanna>

// <itsm-agni>
export const isConferenceCallFieldHidden = () => ff('hide-icc-for-all-users_avyh5', false);
// <itsm-agni>

// <ngregory2>
export const isIssueLinkLimitEnabled = () =>
	ff('issue.details.issue-link-limit-exceed-warning-flag');
// </ngregory2>

// <pjerome>
export const isCreateConfluencePagesEnabled = () =>
	ff('platform.embedded-confluence.jira-issue.create-confluence-pages', false);
// <pjerome>

// <knaithani>
// </knaithani>

// <lward3>
// </lward3>

// <kmaharjan4>
export const isDevOpsAppRecommenderEnabled = () =>
	ff('devops_app_recommendation_on_link_paste', false) &&
	expValEquals('devops_app_recommendation_on_link_paste', 'cohort', 'variation');
// </kmaharjan4>

// <tsirisongkol>
export const isIssueViewRemoveEcosystemBackgroundScriptsFromTTIEnabled = () =>
	ff('issue-view-remove-backgroundscripts-from-critical-fetch_6f6on');
// </tsirisongkol>

// <sluu>

// </sluu>

// <dxu>
export const isAggMigrationCommentsEnabled = () =>
	ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9', false);
export const isAggMigrationCommentsConsistencyEnabled = () =>
	ff('issue-jiv-14530-comments-consistency-check_x716d', false);
// </dxu>

// <jchen2>
export const shouldFireAnalyticsEventForTimeTrackingUpdateFailed = () =>
	ff('jiv-18751-investigate-time-tracking-update-errors_ng38s');
// </jchen2>

// <asheel>
export const isAggMigrationAttachmentsEnabled = () =>
	ff('issue.details.gira-agg-migration-attachments_ecebe', false);
// </asheel>

// <gdeng>
export const isIssueViewWhiteboardsStandaloneEnabled = () =>
	ff('jira-issue-view-whiteboards-standalone', false);
// </gdeng>

// <skashyap>
export const isEmbeddedConfluenceIssueViewCrossJoinEnabled = () =>
	ff('platform_embed-conf_jira-issue_create_cross-join', false);
// </skashyap>

// <jbouzi>
export const isEmbeddedConfluenceIssueLinkedPageCrossJoinEnabled = () =>
	ff('platform.embedded-confluence.jira-issue.linked-page-cross-join', false);
// </jbouzi>

// <inorouzi>
export const isGoalsFieldEnabledInIssueView = () =>
	ff('hrz-716-integrate-goal-picker-into-issue-view', false);
// </inorouzi>

// <aullal>
export const isTokensAndStyledComponentsMigrationEnabled = () =>
	ff('im-tokens-and-styled-components-migration_qpsld', false);
// <aullal>

// <rkumar8>
export const isIssueArchivalFeatureEnabled = () => ff('issue-unarchival_wdpb9', false);
// </rkumar8>

// <msuri>
export const isSentimentCustomFieldEnabled = () => ff('sentiment-custom-field', false);
// </msuri>

// <kenji>
export const isIssueViewClassificationLevelEditabilityEnabled = () =>
	ff('issue-view-classification-level-editability_88zal', false);
// </kenji>

// <ragarwal>
export const isJSMRestrictInternalCommentVisibilityEnabled = () =>
	ff('jsm-restrict-internal-comment-visibility', false);
// </ragarwal>

// <dxu>
export const aggUseIsEditableInViewFlag = () =>
	ff('jiv-17158-agg-use-iseditableinview_8nogh', false);
// </dxu>

// <yvovk>
export const isUIMCascadingSelectFieldEnablead = () =>
	ff('uim-cascading-select-field-support-gic_h1cda', false);
// </yvovk>

// <clawu>
export const isEmbeddedConfluenceCreateContentCrossFlowEnabled = () =>
	ff('platform.embedded-confluence.jira-issue.create-confluence-content-crossflow', false);
// </clawu>

// dxu
export const useMutationForRemainingEstimateField = () =>
	ff('jiv-18175-usemutation-for-remaining-estimate-field_ar10h', false);
// dxu

// aboutinflegel
export const isIssueViewNudgeExperimentEnabled = () =>
	ff('jsw.xflow.issue-view-nudge-experiment', false);
// aboutinflegel

// vyakimenko
export const isCascadingSelectSupportForUIMEnabled = () =>
	ff('jsw.xflow.issue-view-nudge-experiment', false);
// vyakimenko

export const isWhiteboardStandaloneEnabled = () =>
	ff('jira-issue-view-whiteboards-standalone', false);

export const isCrossJoinContentPreviewEnabled = () =>
	ff('platform.embedded-confluence.jira-issue.linked-page-cross-join', false);

// bjayaramareddy
export const isHideLinkedIssueKeyForNonAuthorisesEnabled = () =>
	ff('hide-issue-key-when-linked-issue-has-no-access_8nb8t');
// bjayaramareddy

// <smalviya>
export const changeInSelectFeedbackMessage = () => ff('select-feedback-type-label_683rg', false);
// </smalviya>

// <smalviya>
export const customSelectFeedbackForJira = () =>
	ff('custom-select-feedback-for-issue-view_y8h7f', false);
// </smalviya>

// lmuller
export const isCMDBAttributeIdRemoved = () =>
	ff('assets-rearch-deprecate-attribute-value-id_ykukg', false);
// lmuller

// mawad
export const isExtraPaddingOnIssueActionButtonsEnabled = () =>
	ff('fix-narrow-button-spacing-on-issue-actions_icmq8', false);
// mawad

// <sms2>
export const is3pIssueWatchNudgeEnabled = () =>
	ff('third_party_app_nudge_on_issues_watch', false) &&
	expValEquals('third_party_app_nudge_on_issues_watch', 'cohort', 'variation');
// </sms2>

// <skashyap>
export const isLinkedMentionedRestrictedPageOnClickEnabled = () =>
	ff('linked_mentioned_pages_handle_restricted_page_on_click', false);
// </skashyap>

// <alaktionova>
export const isJPDPanelEnabled = () => ff('polaris.jpd-panel-in-issue-view', false);
// </alaktionova>

// <ksingh4>
export const isFiringErrorAnalyticsAddedToChildIssuePanel = () =>
	ff('adding-error-analytics-to-child-issue-panel-if-issue-types-array-is-empty_tb0ut', false);
// </ksingh4>

// <hali>
export const isIssueViewPlaceholderReminderNudgeEnabled = () =>
	ff('reminder-nudge-for-failed-xflow_z8l8q', false);
// <hali>

// <fghilini>
export const isForgeUiModificationsScreenTabsSupportEnabled = () =>
	fg('forge_ui_modifications_for_screen_tabs') ||
	ff('forge-ui-modifications-screentabs_qoa33', false);
// </fghilini>

// <skashyap>
export const isIssueViewCreateConfluencePageTemplateBrowserEnabled = () =>
	ff('jira.issue-view.create-confluence-page.template-browser', false);
// </skashyap>

// <mjarzebska>
export const isUimStatusFieldSupportEnabled = () =>
	ff('ui-modifications-issue-view-status-custom-field-support_hwx15', false);
// </mjarzebska>

// <dbartlett>
export const isIssueViewEndUsersCrossFlowViaE2EEnabled = () =>
	ff('placeholder-template-end-users-cross-flow-via-e2e-flow-killswitch_4bfgx', false);
// </dbartlett>

// <dvedi>
export const fixforJRACLOUD84181 = () => ff('fix-for-jracloud-84181_dm5fg', false);
// </dvedi>

// <jgrose>
export const isRelayMigrationIssueFieldsSprintEnabled = () =>
	ff('relay-migration-issue-fields-sprint_jozpu');
export const isRelayMigrationIssueFieldsMetadataEnabled = () =>
	ff('relay-migration-issue-fields-metadata_d42b0');
// </jgrose>

// <relay-code-gen-Assignee>
export const isRelayMigrationIssueFieldAssigneeEnabled = () =>
	ff('relay-migration-issue-fields-assignee-ld', false);
// </relay-code-gen-Assignee>

// <relay-code-gen-Number>
export const isRelayMigrationIssueFieldNumberEnabled = () =>
	ff('relay-migration-issue-fields-number_q0em4', false);
// <relay-code-gen-Number>

// <ksingh4>
export const isCompiledReactMigrationPhase1Enabled = () =>
	ff('compiled-react-migration-issue-view_zilee', false);
// </ksingh4>

// <ksingh4>
export const isCompiledReactMigrationPhase2Enabled = () =>
	ff('compiled-react-migration-issue-view_u9myz', false);
// </ksingh4>

// <jenga>
export const isInvestigateButtonEnabledForOnlyPremium = () =>
	ff('enable_jsm_repackaging_changes_vtwk9', false);
// </jenga>

// <jkalyan>
export const isComPalSunsetAndRemoveDotMenuEnabled = () =>
	ff('compal-sunset-and-remove-dot-menu_66j8q', false);

export const isComPalDotMenuShortcutIntegrationEnabled = () =>
	ff('compal-dot-menu-shortcut-integration_dvvg2', false);
// </jkalyan>

// <relay-code-gen-SingleLineText>
export const isRelayMigrationIssueFieldSingleLineTextEnabled = () =>
	ff('relay-migration-issue-fields-single-line-text_in6w6', false);
// <relay-code-gen-SingleLineText>

// <relay-code-gen-Date>
export const isRelayMigrationIssueFieldDateEnabled = () =>
	ff('relay-migration-issue-fields-date_ptj72', false);
// <relay-code-gen-Date>

// <relay-code-gen-Project>
export const isRelayMigrationIssueFieldProjectEnabled = () =>
	ff('relay-migration-issue-fields-project-select_w0xem', false);
// <relay-code-gen-Project>

// <aoshannessy>
export const isTeamAttributeAdded = () => ff('add-team-id-analytics-data');
// </aoshannessy>

// <relay-code-gen-DateTime>
export const isRelayMigrationIssueFieldDateTimeEnabled = () =>
	ff('relay-migration-issue-fields-date-time_eaqd2', false);
// <relay-code-gen-DateTime>

// <alaktionova>
export const isJPDDeliveryLinkTypeFilteringEnabled = () =>
	ff('polaris.jpd-panel-filter-by-delivery-link-type', false);
// </alaktionova>

// <relay-code-gen-Url>
export const isRelayMigrationIssueFieldUrlEnabled = () =>
	ff('relay-migration-issue-fields-url_ob8uu', false);
// <relay-code-gen-Url>

// <relay-code-gen-SecurityLevel>
export const isRelayMigrationIssueFieldSecurityLevelEnabled = () =>
	ff('relay-migration-issue-fields-security-level_7y5uv', false);
// <relay-code-gen-SecurityLevel>

export const isRelayMigrationIssueFieldSummaryEnabled = () =>
	ff('relay-migration-issue-fields-summary_eog1v', false);
// <relay-code-gen-Summary>

// <jchau>
export const isRelayMigrationIssueFieldSingleLineTextMessageEnabled = () =>
	ff('relay-migration-issue-fields-single-line-text-message', false);

export const isRelayMigrationIssueFieldsUserEnabled = () => ff('relay-migration-issue-fields-user');

export const isRelayMigrationIssueFieldApproversEnabled = () =>
	ff('relay-migration-issue-fields-approvers', false);

// </jchau>

// <relay-code-gen-TimeTracking>
export const isRelayMigrationIssueFieldTimeTrackingEnabled = () =>
	ff('relay-migration-issue-fields-time-tracking_eefh0', false);
// <relay-code-gen-TimeTracking>

// <relay-code-gen-CheckboxSelect>
export const isRelayMigrationIssueFieldCheckboxSelectEnabled = () =>
	ff('relay-migration-issue-fields-checkbox-select_ytl5n', false);
// <relay-code-gen-CheckboxSelect>

// <relay-code-gen-People>
export const isRelayMigrationIssueFieldPeopleEnabled = () =>
	ff('relay-migration-issue-fields-people', false);
// <relay-code-gen-People>

// <relay-code-gen-Color>
export const isRelayMigrationIssueFieldColorEnabled = () =>
	ff('relay-migration-issue-fields-color_wrbay', false);
// <relay-code-gen-Color>

// <relay-code-gen-Components>
export const isRelayMigrationIssueFieldComponentsEnabled = () =>
	ff('relay-migration-issue-fields-components-select_prpz1', false);
// <relay-code-gen-Components>

// <relay-code-gen-OriginalEstimate>
export const isRelayMigrationIssueFieldOriginalEstimateEnabled = () =>
	ff('relay-migration-issue-fields-original-estimate_eb4wv', false);
// <relay-code-gen-OriginalEstimate>

// <relay-code-gen-UserPicker>
export const isRelayMigrationIssueFieldUserPickerEnabled = () =>
	ff('relay-migration-issue-fields-multi-user', false);
// <relay-code-gen-UserPicker>

// <psavchenko>
export const isRelayMigrationIssueTypeSwitcherEnabled = () =>
	ff('relay-migration-issue-fields-issue-type-switcher_l0lrt', false);
// </psavchenko>

// <relay-code-gen-Watchers>
export const isRelayMigrationIssueFieldWatchersEnabled = () =>
	ff('relay-migration-issue-fields-watchers_iqhn4', false);
// <relay-code-gen-Watchers>

// <relay-code-gen-Resolution>
export const isRelayMigrationIssueFieldResolutionEnabled = () =>
	ff('relay-migration-issue-fields-resolution_86iya', false);
// <relay-code-gen-Resolution>

// <relay-code-gen-GroupPicker>
export const isRelayMigrationIssueFieldGroupPickerEnabled = () =>
	ff('relay-migration-issue-fields-group-picker', false);
// <relay-code-gen-GroupPicker>
